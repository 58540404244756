/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
//= require jquery
//= require jquery_ujs


import jquery from 'jquery';
window.$ = window.jquery = jquery;
window.$ = window.jQuery = jquery;

import Swal from 'sweetalert2/dist/sweetalert2.js'
window.Swal = Swal;

import moment from 'moment';
window.moment = moment;

require("bootstrap");
require("moment");
require("@nathanvda/cocoon");
require("packs/vendor/summernote");
require("packs/vendor/bootstrap-datetimepicker");

require("packs/vendor/select2.min");
require("packs/vendor/Chart.min");

require("packs/vendor/admin-lte");
require("packs/vendor/main-admin");

require("packs/script");


//= require jquery.datetimepicker
//= require datetimepicker

