$(document).ready(function() {
  // Initialize select2
  $('.select2').select2();

  // AJAX request on clicking token button
  if ($('.b-token').length > 0) {
    $('.b-token').on('click', function() {
      $.ajax({
        url: ('/tokens/sign_in'),
        type: 'get',
        data: {
          email: $('#user_intool_email').val()
        },
        beforeSend: function(xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        }
      });
    })
  };

  // Confirmation dialog for deletion
  if ($(".btn-delete").length > 0) {
    $(".btn-delete").on("click", function() {
      var dataId = $(this).data("id");
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          $("#voucher-" + dataId + ", #blog-" + dataId + ", #event-" + dataId + ", #category-" + dataId).trigger("click");
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        }
      })
    })
  }

  if($('.modal-show').length > 0){
    $('.modal-show').on('click', function(){
      elmID = $(this).data('target');
      $("#" + elmID).modal('show');
    });

    $('.modal-dismiss').on('click', function(){
      elmID = $(this).data('target');
      $("#" + elmID).modal('hide');
    })
  }

  if($('#company_code').length > 0){
    $('#company_code').change(function(){
      if($('#company_code').val().length > 0){
        front_token = generateRandomText(6);
        back_token = generateRandomText(6);
        token = (front_token + $('#company_code').val() + back_token);
        $('#company_auth_token').val(token);
        $('#auth_token_text').val(token);
      }else{
        $('#company_auth_token').val('');
        $('#auth_token_text').val('');
      }
    })
  }
  

  // Add new speaker form box
  $('#add-speaker').click(function() {
    var index = $('.form-box').length;
    var newSpeakerForm = $('.form-box').first().clone();
    newSpeakerForm.attr('id', 'form-box-' + index);
    newSpeakerForm.find(':input').each(function() {
      $(this).val('');
    });
    $('#event_speaker_list').append(newSpeakerForm);
  });

  // Initialize timepicker
  $('.timepicker').datetimepicker({
    format: 'LT'
  });
  $('#timepicker1').datetimepicker({
    format: 'LT'
  });
  $('#timepicker2').datetimepicker({
    format: 'LT'
  });

  $('.date-picker').datetimepicker({
    format: 'YYYY/MM/DD'
  });
  $('#datepicker1').datetimepicker({
    format: 'YYYY/MM/DD'
  });
  $('#datepicker2').datetimepicker({
    format: 'YYYY/MM/DD'
  });

  // Handle switch status using localStorage
  var switchElement = $('#customSwitch1');
  var switchStatus = localStorage.getItem('customSwitch1');
  if (switchStatus !== null) {
    switchElement.prop('checked', switchStatus === 'on');
  }

  // Update Switch Status
  function updateSwitchStatus() {
    localStorage.setItem('customSwitch1', switchElement.prop('checked') ? 'on' : 'off');
  }
  switchElement.on('change', updateSwitchStatus);

  // Sorting functionality
  if ($('.sorting').length > 0) {
    $('.sorting').click(function() {
      var current_class = $(this).attr("class");
      var current_data_id = $(this).attr("data-id");
      var current_id = $(this).attr("id");

      if (current_class == "data-sort sorting") {
        new_class = "data-sort sorting_asc";
        current_data_id += "-desc"
      } else if (current_class == "data-sort sorting_asc") {
        new_class = "data-sort sorting_desc";
        current_data_id += "-asc"
      } else if (current_class == "data-sort sorting_desc") {
        new_class = "data-sort sorting_asc";
        current_data_id += "-desc"
      }

      $(".data-sort").each(function() {
        $(this).attr("class", "data-sort sorting");
      });

      $("#" + current_id).attr("class", new_class);

      $("#last_sort").val(current_data_id);

      reload_list({
        sort: current_data_id,
        per_page: $("#table-data-show").val(),
        page: $("#page").val(),
        search: $('#search-box-table').val(),
        format: 'js'
      });
    });
  };

  //Pagination handling
  set_paginate();

  // Move onchange attribute inside $(document).ready function
  $('.custom-file-input').on('change', function() {
    var fileName = $(this).val().split('\\').pop();
    if(fileName != ""){
      $(this).next('.custom-file-label').html(fileName);
    }else{
      $(this).val('');
      $(this).next('.custom-file-label').html('Choose file');
    }
  });


});

//Move set_paginate and reload_list outside $(document).ready()
window.set_paginate = function() {
  $('.page-link').click(function() {
    var page = $(this).attr("data-id");
    reload_list({
      page: page,
      per_page: $("#table-data-show").val(),
      sort: $("#last_sort").val(),
      search: $('#search-box-table').val(),
      format: 'js'
    });
    $("#page").val(page);
  });

  $("#table-data-show").on('change', function() {
    var page = $("#page").val();
    reload_list({
      page: page,
      per_page: $("#table-data-show").val(),
      sort: $("#last_sort").val(),
      search: $('#search-box-table').val(),
      format: 'js'
    });
  })
}

window.reload_list = function(params) {
  $.ajax({
    url: $("#intools-app").data('url'),
    method: "get",
    data: params,
    dataType: "script"
  })
}


window.generateRandomText = function(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}





